<template>
  <div class="page-all">
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="steps-transparent mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account detail tab -->
      <tab-content
        title="App Name"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer
          ref="appNameRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                App Name
              </h5>
              <small class="text-muted">
                Enter Your App Name Here. This will the name shown to user under your app icon.
              </small>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="App Name"
                label-for="appname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="App Name"
                  rules="required"
                >
                  <b-form-input
                    id="appname"
                    v-model="appName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" />
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details -->
      <tab-content
        title="Website URL"
        icon="feather icon-user"
        :before-change="validationURLInfo"
      >
        <validation-observer
          ref="urlinfo"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Website URL
              </h5>
              <small class="text-muted">Enter a working website URL here</small>
            </b-col>
            <b-col md="12">
              <b-form-group
                label-for="websiteurl"
                label="Website URL"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Website Url"
                  rules="required|url"
                >
                  <b-form-input
                    id="websiteurl"
                    v-model="websiteUrl"
                    placeholder="https://www.examplesite.com"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" />

          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address -->
      <tab-content
        title="App Icon"
        icon="feather icon-map-pin"
        :before-change="validationAppLogoURL"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              App Icon
            </h5>
            <small class="text-muted">Add an Icon for your App here. Make sure it's 512px X 512px in size. Supported formats are .png/.jpg/.jpeg</small>
          </b-col>
          <b-col md="12">
            <div class="imgContainer">
              <image-upload
                class="imgUploader"
                text="Drop file here or click to upload App Logo"
                :image-url="appLogo"
                @image-uploaded="imageUploaded"
              />
            </div>

          </b-col>
        </b-row>
      </tab-content>

      <!-- social link -->
      <tab-content
        title="App Colors"
        icon="feather icon-link"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              App Colors
            </h5>
            <small class="text-muted">Setup main colors of your App from here</small>
          </b-col>

          <b-col
            md="6"
            class="d-flex align-items-center color-horizontal-align"
          >
            <label
              for="head"
              class="app_color_label"
            >Primary Color</label>
            <input
              id="head"
              v-model="primaryColor"
              type="color"
              class="color_input"
              name="head"
            >
          </b-col>
          <b-col
            md="6"
            class="d-flex align-items-center"
          >
            <label
              for="head"
              class="app_color_label"
            >Secondary Color</label>
            <input
              id="head"
              v-model="accentColor"
              type="color"
              class="color_input"
              name="head"
            >
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col
            md="6"
            class="d-flex align-items-center color-horizontal-align"
          >
            <label
              for="primaryDarkColor"
              class="app_color_label"
            >Primary Color Dark</label>
            <input
              id="primaryDarkColor"
              v-model="primaryDarkColor"
              type="color"
              class="color_input"
              name="primaryDarkColor"
            >
          </b-col>
          <b-col
            md="6"
            class="d-flex align-items-center"
          >
            <label
              for="websiteColor"
              class="app_color_label"
            >Background Color</label>
            <input
              id="websiteColor"
              v-model="websiteColor"
              type="color"
              class="color_input"
              name="websiteColor"
            >
          </b-col>
        </b-row>
      </tab-content>
      <b-overlay
        :show="isLoading"
        no-wrap
      />
    </form-wizard>

  </div>

</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {
  BRow,
  BCol,
  BOverlay,
  BFormGroup,
  BFormInput,
  // BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required } from '@validations'
import { codeIcon } from './code'
import ImageUpload from '../../components/ImageUpload.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    ImageUpload,
    // vSelect,
    // BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      isLoading: false,
      colors: {
        hex: '#194d33',
        hsl: {
          h: 150,
          s: 0.5,
          l: 0.2,
          a: 1,
        },
        hsv: {
          h: 150,
          s: 0.66,
          v: 0.30,
          a: 1,
        },
        rgba: {
          r: 25,
          g: 77,
          b: 51,
          a: 1,
        },
        a: 1,
      },
      appName: '',
      websiteUrl: '',
      primaryColor: '#3f51b5',
      accentColor: '#29C2A8',
      primaryDarkColor: '#7656BB',
      websiteColor: '#FFFFFF',
      appLogo: null,
      codeIcon,
      required,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        maxFilesize: 10,
        acceptedFiles: '.jpg, .jpeg, .png',
        addRemoveLinks: false,
        maxFiles: 1,
        headers: { 'My-Awesome-Header': 'header value' },
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Drop files here or click to upload.",
      },
    }
  },
  computed: {
    loadingStatus() {
      return this.$store.getters['loading/loadingStatus']
    },
  },
  watch: {
    loadingStatus(payload) {
      this.isLoading = payload.isLoading
    },
  },
  methods: {
    formSubmitted() {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to Save the changes', {
          title: 'Please Confirm',
          size: 'md',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$store.dispatch('appSite/addNewApp', {
              appLogo: this.appLogo,
              appName: this.appName,
              websiteUrl: this.websiteUrl,
              primaryColor: this.primaryColor,
              accentColor: this.accentColor,
              primaryDarkColor: this.primaryDarkColor,
              websiteColor: this.websiteColor,
            }).then()
          }
        })
    },
    onTotasterClick() {
      this.$store.dispatch('toaster/setMessage', {
        title: 'New App Added Successfully',
        icon: 'EditIcon',
        variant: 'success',
      })
    },
    imageUploaded(event) {
      this.appLogo = event
    },
    onChange(val) {
      this.colors = val
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.appNameRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationURLInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.urlinfo.validate().then(success => {
          if (success) {
            if (this.validateWebsiteURL(this.websiteUrl)) {
              resolve(true)
            } else {
              reject()
            }
          } else {
            reject()
          }
        })
      })
    },
    validationAppLogoURL() {
      return new Promise((resolve, reject) => {
        if (this.appLogo) {
          resolve(true)
        } else {
          this.$store.dispatch('toaster/setMessage', {
            title: 'Please Upload an Image',
            icon: 'UploadIcon',
            variant: 'danger',
          })
          reject()
        }
      })
    },
    validateWebsiteURL(siteUrl) {
      try {
        const url = new URL(siteUrl)
        console.log(url)
      } catch (e) {
        this.$store.dispatch('toaster/setMessage', {
          title: 'Please verify you have added https:// or http://',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        })
        return false
      }
      return true
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
  metaInfo: {
    // Overwrite the SEO title.
    title: 'Create New App',
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  .app_color_label{
    padding-right: 1rem;
    min-width: 12rem;
    font-size: 14px;
    justify-self: center;
  }
  .color_input{
    width:70px;
    height: 30px;
  }
  @media (max-width: 560px) {
    .color-horizontal-align{
      padding-bottom: 1rem;
    }
}
.page-all{
  display: flex;
  width:100%;
  // height: 100vh;
  align-items: center;
  justify-content: center;
}
.dark-layout .b-overlay .bg-light {
    background-color: #161d31 !important;
}
// .imgContainer{
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .imgUploader{
//   max-height: 200px;
//   max-width: 100px;
// }
</style>
